import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_NODE_ENV === "prod") {
  console.log = () => {};
}

if (process.env.REACT_APP_NODE_ENV === "prod") {
  amplitude.init("a10a720247ad4169d20b44e95881751f", {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  }); // Production
} else if (process.env.REACT_APP_NODE_ENV === "staging") {
  amplitude.init("145cf74bda8971b841c8f67e9c88333b", {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  }); // Staging ("Dev")
} else {
  amplitude.init("145cf74bda8971b841c8f67e9c88333b", {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  }); // Local (also points to Dev)
}

if (
  process.env.REACT_APP_NODE_ENV === "prod" ||
  process.env.REACT_APP_NODE_ENV === "staging"
) {
  Sentry.init({
    dsn: "https://36fa9863fe985863d998c617f0b19828@o4508461760839680.ingest.us.sentry.io/4508488645869568",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    ignoreErrors: ["window.nativeAppNavTo is not a function"],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
